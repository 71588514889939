<template>
  <div>
    <h1>Payment received!</h1>
    <p>
      Thanks for your payment, we have received it in good order.
    </p>
    <p>
      <v-btn outlined color="primary" to="/profile">Your Profile</v-btn>
      <v-btn outlined color="primary" to="/eventmanager">Event Manager</v-btn>
    </p>
  </div>
</template>


<script>

export default {
  name: "Success",
  props: {
  },
  data: function() {
    return {
    };
  },
  mounted() {
  },
  methods: {
  },
};
</script>

<style scoped>
</style>